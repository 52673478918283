/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  padding-bottom: 3rem;
  font-size: .875rem;
}

body {
  background-color: #f5f5f5;
}

/* html, body {
  min-height: 100%;
}

#root {
  min-height: 100%;
} */

#root {
  /* height: 100%; */
  width: 100%;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-control {
  font-size: 16px;
  padding: 10px;
  position: relative;
  height: auto;
}

.form-signin .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin .form-control:not(:last-child) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.form-control:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus .show>.btn-primary.dropdown-toggle:focus,
.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus,
.show>.btn-danger.dropdown-toggle:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.page-link:focus,
.custom-control-input:focus~.custom-control-label::before,
.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: none;
}

.toast-container {
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 1rem;
  z-index: 1022;
}

button:focus {
  outline: none;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link i.fa {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover i.fa,
.sidebar .nav-link.active i.fa {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

table {
  table-layout: fixed;
  overflow-wrap: anywhere;
}